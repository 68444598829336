


/* pie de pagina */
.footer-page{
    height: 300px;
    width: 100%;
    font-size: 40px;
    text-align: center;
    display: grid;
    grid-template-columns: 30% 40% 25%;
    grid-template-areas:
        "a b d";
    background-color: #300225;
    overflow: hidden;
  }
  
  .footer-1{
    grid-area: a;
    margin-top: 40px;
  
  }
  .footer-1 img{
    width: 150px;
  }
  .footer-1 p{
    font-size: 25px;
    color: white;
    font-family: "Bodoni MT", serif;
  }
  
  .footer-2{
    margin-top: 40px;
    text-align: center;
    grid-area: b;
  }
  .footer-2 p{
    color: white;
    font-size: 18px;
  }
  .title-foot{
    text-align: center;
    font-weight: bold;
  }
  
  .footer-3{
    grid-area: d;
    margin-top: 40px;
    text-align: left;
  }
  .footer-3 p{
    color: white;
    font-size: 18px;
  }
  
  .facebook-red{
    background-color: rgb(46, 46, 164);
    margin: 0 auto;
    text-align: left;
    padding-top: 10px;
    padding-left: 20px;
    width: 80%;
    position: relative;
    border-radius: 10px;
    margin-bottom: 20px;
  }
  .whatsapp-red{
    background-color: rgb(31, 147, 22);
    margin: 0 auto;
    text-align: left;
    padding-top: 10px;
    padding-left: 20px;
    width: 80%;
    position: relative;
    border-radius: 10px;
  }
  .whatsapp-red a:hover{
    cursor: pointer;
  }
  
  .redes-sociales{
    color: white;
    text-decoration: none;
  }
  .redes-sociales svg{
    font-size: 40px;
  }
  .redes-sociales-sp{
    font-size: 20px;
    color: white;
    position: absolute;
    padding-left: 20px;
    padding-top: 10px;
    font-family: "Bodoni MT", serif;
  }

  @media only screen and (max-width: 480px) {
    .footer-page{
        font-size: 40px;
        text-align: center;
        display: grid;
        grid-template-columns: 30% 40% 25%;
        grid-template-areas:
            "a a a"
            "b b b"
            "d d d";
        height: auto;
    }
    .footer-1 img{
        width: 70px;
    }
    .footer-1 p{
        font-size: 20px;
        color: white;
        font-family: "Bodoni MT", serif;
        letter-spacing: 0.5rem;
    }
    .footer-2{
        margin-top: 0px;
        text-align: center;
        padding-left: 10px;
    }  
    .footer-3{
        margin-top: 10px;
        padding-bottom: 20px;
    }
  }
  