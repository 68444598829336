
/* slider de la pagina */
.slider-navbar{
    width: 100%;
    background-image: url("../../imgcomponents/fondo-wall-311.jpg");
    background-size: cover; 
    background-position-y: 10%; 
    background-position-x: 90%;
    height: 95vh;
    position: relative;
    filter: brightness(80%) hue-rotate(330deg);
}
.capa-slider-navbar{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3); /* Color de fondo semitransparente */
}

.text-slider{
    color: white;
    font-weight: bold;
    -webkit-text-stroke: 2px black; /* Para navegadores WebKit (Chrome, Safari) */
    font-family: "Bodoni MT", serif;
    font-size: 180px;
    text-align: center;
    height: 100px;
}
@media only screen and (max-width: 900px) {
  .text-slider{
    font-size: 120px;
  }
}


.text-slider-p{
  display: none;
  color: white;
  font-family: "Bodoni MT", serif;
  font-size: 50px;
  width: 70%;
  margin: 0 auto;
  text-align: center;
}

@media only screen and (max-width: 480px) {
  .text-slider{
    font-size: 80px;
  }
  .slider-navbar{
    height: 40vh;
  }
}