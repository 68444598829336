.slid1{
    height: 100px;
    width: 100%;
    /* background-color: #300225; */
    background-image: url("../imgcomponents/fondo-wall-311.jpg");
    background-size: cover;
    background-position-y: 20%;
    position: relative;
    overflow: hidden;
    margin: 0 auto;
    text-align: center;
}
.categorias{
    height: 100%;
    width: 100%;
    position: relative;
    overflow: hidden;
    margin: 0 auto;
    text-align: center;
}
.slid1search{
    padding-top: 17px;
    position: relative;
}
.slid1search select{
    margin-top: -40px;
}
.select-product{
    width: 150px;
    height: 52px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    background-color: rgb(201, 198, 198);
    border: none;
    outline: none;
    font-size: 20px;
    padding-bottom: 1px;
    padding-left: 10px;
}
.select-product option{
    background-color: white;
}
.input-seacrh-prod{
    height: 50px;
    width: 40%;
    border: none;
    font-size: 20px;
    padding-left: 10px;
    padding-right: 10px;
    outline: none;
}
.input-search-prod:focus {
    outline: none !important;
    border: none !important;
}
.search-button{
    width: 50px;
    height: 52px;
    background-image: url("../imgcomponents/search.png");
    background-size: 70%;
    background-repeat: no-repeat;
    background-position: center;
    background-color: rgb(254, 254, 167);
}
.slid1search button{
    position: absolute;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border: none;
}

@media only screen and (max-width: 480px) {
    .categorias .text-cat{
        font-size: 15px;
    }
    .navbar-menu{
        overflow-x: hidden;
    }
    .slid1{
        width: auto;
        text-align: left;
        padding-left: 20px;
    }
    .input-seacrh-prod{
        width: 60%;
    }
    .select-product{
        width: 30px;
        height: 52px;
    }
}

/* secciones del catalogo */

.catalogo-seccions{
    width: 100%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 20% 80%;
    grid-template-areas:"a b";
    position: relative;
    height: auto;
}
.categorias{
   grid-area: a;
}
.text-cat{
    font-size: 30px;
    text-align: center;
}
.categorias ul li{
    list-style: none;
}
.ul-cat{
    padding: 10px;
}
.list-item {
    display: flex;
    align-items: center;
    font-size: 20px;
    font-family: sans-serif;
}
.icon-image {
    width: 40px;
    height: 40px;
    margin-right: 10px;
}
.busqueda{
    grid-area: b;
}
.result-search, .result-search2{
    width: 100%;
    height: 20px;
    font-size: 22px;
}
.result-search p{
    overflow: hidden;
}
.result-search2 p{
    overflow: hidden;
}
.carts-search{
    width: 100%;
    margin: 0 auto;
    display: grid;
    margin-top: 30px;
    grid-template-columns: 24% 24% 24% 24%;
    grid-template-areas: 
      "carta1 carta2 carta3 carta4";
    grid-gap: 10px;
}

@media only screen and (max-width: 480px) {
    .ul-cat li p{
        display: none;
    }
    .ul-cat li{
        padding-bottom: 15px;
    }
    
    .catalogo-seccions{
        width: 100%;
        grid-template-columns: 20% 80%;
        grid-template-areas:"a b";
        overflow: hidden;
    }
    .carts-search{
        width: 100%;
        margin: 0 auto;
        display: grid;
        margin-top: 30px;
        overflow: hidden;
        grid-template-columns: 21% 21% 21% 21%;
        grid-template-areas: 
          "carta1 carta1 carta1 carta1" 
          "carta2 carta2 carta2 carta2"
          "carta3 carta3 carta3 carta3"
          "carta4 carta4 carta4 carta4";
        grid-gap: 10px;
    }
    .cargando img{
        padding-top: 50%;
        width: 100%;
    }
    .result-search{
        padding-left: 5px;
    }
    .result-search2{
        padding-left: 5px;
    }
    .subcategorias{
        height: auto;
    }
    .label-aretes{
        display: none;
    }    
    .carta-prod{
        width: 100%;
        height: 140px !important;
        display: grid;
        overflow: hidden;
        grid-template-columns: 60% 40%;
        grid-template-areas: 
          "a b"
    }
    .img-format{
        grid-area: a;
        height: 100%;
    }
    .descripcion-carta-vid{
        padding-top: 15px;
        grid-area: b;
    }
    .list-item:hover{
        background-color: white !important;
        cursor: pointer;
    }
    .list-item:hover .icon-image {
        filter: brightness(0) invert(0) !important;
    }
}

.carta-prod{
    width: 100%;
    height: 400px;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    border: 1px solid rgb(211, 210, 210);
    box-shadow: 5px 0px 7px rgba(0, 0, 0, 0.4);
}
.carta-prod:hover{
    transform: scale(1.01);
    cursor: pointer;
}
.img-format {
    width: 100%;
    height: 75%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}
.descripcion-carta-vid{
    background-color: white;
    height: 25%;
    width: 100%;
    text-align: center;
    margin-top: -20px;
}
.title-carta-vid{
    font-size: 20px;
    font-weight: bold;
}
.list-item:hover{
    background-color: #300225;
    color: white;
    cursor: pointer;
}
.list-item:hover .icon-image {
    filter: brightness(0) invert(1);
    animation: swing 1s infinite;
}

@keyframes swing {
    0% {
        transform: rotate(0deg);
    }
    50% {
        transform: rotate(30deg);
    }
    100% {
        transform: rotate(0deg);
    }
}



.search-button:hover{
    cursor: pointer;
}
.cargando{
    text-align: center;
    width: 100%;
}
.subcategorias{
    width: 100%;
    margin: 0 auto;
    display: grid;
    margin-top: 30px;
    grid-template-columns: 32% 32% 32%;
    grid-area: "a b c";
    height: 50px;
    grid-gap: 10px;
    overflow: hidden;
}
.subcat123{
    width: 100%;
}
.label-aretes{
    font-size: 25px;
    font-weight: bold;
}
.filtro-aretes{
    height: 40px;
    border-radius: 5px;
    font-size: 20px;
    width: 200px;
}

.subcat1, .subcat2, .subcat3{
    font-size: 20px;
    border-radius: 10px;
    text-align: center;
    background-color: #430234;
    color: white;
    padding-top: 10px;
}
.subcat1:hover, .subcat2:hover, .subcat3:hover{
    cursor: pointer;
    background-color: #79035e;
}
.quiz-search{
    font-weight: bold;
    color: #79035e;
}
.quiz-search:hover{
    cursor: pointer;
}

.promo{
    width: 100%;
    overflow: hidden;
    /* background-color: rgb(231, 231, 231); */
    background-color: white;
    height: 80vh;
    position: relative;
}
.prommsecciones{
    width: 90%;
    margin: 0 auto;
    display: grid;
    margin-top: 100px;
    grid-template-columns: 20% 20% 20%;
    grid-template-areas: 
        "a b c";
    grid-gap: 10px;
}
.prom1{grid-area: a;}
.prom2{grid-area: b;}
.prom3{grid-area: c;}
