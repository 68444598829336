html, body{
    height: 100%;
    margin: 0;
    padding: 0;
}

.container-home{
    width: 100%;
    height: 100%;    
}

.frase-p{
  color: #300225;
  font-size: 50px;
}

.navbar-menu {
    display: flex;
    align-items: center;
    width: 100%;
}
  
  .logo-company {
    padding-top: 5px;
    flex: 1;
    padding-bottom: 5px;
  }
  .logo-company img{
    width: 200px;
    height: 40px;
  }
  
  .menu-items {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex: 1;
    background-color: white;
  }
  
  .menu-items ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: flex-end;
  }
  
  .menu-items li {
    margin-right: 10px;
    width: 150px;
    height: 100%;
    text-align: center;
    font-size: 25px;
    position: relative;
}
  .menu-items li a {
    text-decoration: none;
    border-radius: 5px;
    color: black;
    font-weight: 400;
}
  
  .menu-items li:hover {
    background-color: #611545;
  }
  .menu-items li:hover a{
    color: white;
  }

/* cuerpo de home */

.body-container-home{
    width: 100%;
    height: auto;
    padding-bottom: 100px;
}
.container-carts{
    margin: 0 auto;
    display: grid;
    grid-template-columns: 22% 22% 22% 22%;
    grid-template-areas:"a b c d";
    grid-gap: 5%;
    width: 85%;
    position: relative;
    z-index: 1;
    margin-top: -100px;
}
.carts-1{grid-area: a;}
.carts-2{grid-area: b;}
.carts-3{grid-area: c;}
.carts-4{grid-area: d;}
.carts-1, .carts-2, .carts-3, .carts-4{
    height: 350px;
    width: 100%;
    border-radius: 20px;
    border: 2px solid #a44c84;
    position: relative;
}
.carts-1{
  background-image: url("../imgcomponents/collar.jpg");
  overflow: hidden;
  background-size: cover;
  transition: background-size 10s;
}
.carts-2{
  background-image: url("../imgcomponents/aretes.jpg");
  overflow: hidden;
  background-size: cover;
  transition: background-size 10s;
}
.carts-3{
  background-image: url("../imgcomponents/manualidades.jpg");
  overflow: hidden;
  background-size: cover;
  transition: background-size 10s;
}
.carts-4{
  background-image: url("../imgcomponents/arte.jpg");
  overflow: hidden;
  background-size: cover;
  transition: background-size 10s;
}
.carts-1 .carts-capa:hover,
.carts-2 .carts-capa:hover, 
.carts-3 .carts-capa:hover, 
.carts-4 .carts-capa:hover{
  background: none;
}
.carts-1 .carts-capa:hover .p-carts
.carts-2 .carts-capa:hover .p-carts,
.carts-3 .carts-capa:hover .p-carts,
.carts-4 .carts-capa:hover .p-carts{
  display: none;
}
.carts-1:hover,
.carts-2:hover,
.carts-3:hover,
.carts-4:hover{
  background-size: 170%;
  background-position-y: 40%; 
  background-position-x: 40%;
}
.p-carts{
  position: absolute;
  bottom: 0;
  font-size: 40px;
  font-weight: bold;
  color: white;
}
.carts-capa{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(131,58,180);
  background: linear-gradient(0deg, rgb(83, 41, 111) 0%, rgba(237, 76, 178, 0.151) 43%);
  border-radius: 20px;
}

@media only screen and (max-width: 480px) {
  .container-carts{
    display: none;
  }
}

/* body descripcion de paper */

.body-container-info{
  margin: 0 auto;
  width: 80%;
}
.body-info-acerca{
  font-size: 30px;
  font-family: 'Araboto';
  font-weight: bold;
  text-align: center;
}
.body-info-frase{
  font-family: sans-serif;
  font-weight: bold;
  font-size: 70px;
  text-align: center;
}
.body-info-details{
  font-size: 40px;
  text-align: center;
  display: grid;
  grid-template-columns: 30% 70%;
  grid-template-areas:
      "a b";
}
.body-info-details-1{
  grid-area: a;
}
.body-info-details-1 img{
  width: 300px;
  height: 300px;
}
.body-info-details-2{
  grid-area: b;
  text-align: justify;
}
.body-info-details-2{
  overflow: hidden;
  height: 350px;
  font-size: 32px;
  width: 80%;
}

.body-abstract{
  width: 100%;
  background-image: url('../imgcomponents/abstrac.png'); 
  height: 100vh;
  background-position-y: 35%;
  position: relative;
  margin-top: -40px;
}
@media only screen and (max-width: 480px) {
  .body-info-frase p{
    font-size: 40px;
  }
  .body-abstract{
    height: auto;
    position: relative;
    margin-top: -40px;
    background-position-x: 80%;
    padding-bottom: 30px;
  }
  .body-info-details-1 img{
    display: none;
  }
  .body-info-details{
    grid-template-areas:
        "b b";
  }
  .body-info-details-2{
    overflow: hidden;
    font-size: 27px;
    width: 100%;
    height: auto;
    color: #000;
    background-color: rgba(255, 255, 255, 0.492);
  }
}

/* apartado de direccion y correo  */
.contact{
  width: 100%;
  display: grid;
  grid-template-columns: 50% 50% ;
  grid-template-areas:
      "a b";
  background-repeat: no-repeat;
  overflow: hidden;
  background-size: cover;
}
@media only screen and (max-width: 900px) {
  .contact{
    grid-template-areas:
        "b b"
        "a a";
    padding-bottom: 20px;
  }
}
@media only screen and (max-width: 480px) {
  .contact{
    grid-template-areas:
        "b b"
        "a a";
  }
  .direccion-maps{
    width: 100%;
    margin: 0 auto;
    text-align: center;
   
  }
  .info-direccion-empresa {
    width: 100% !important;
  }
  .mapa-google{
    width: 100% !important;
  }
  .mapa-google iframe{
    height: 350px;
  }
  .info-direccion-empresa p {
    width: 100%;
  }
}


.formulario{
  grid-area: a;
  width: 100%;
  text-align: center;
}
.input-form{
  padding-bottom: 10px;;
}
.input-form input{
  border: none;
  border-radius: none;
  height: 60px;
  font-size: 20px;
  outline: none;
  border-bottom: 2px solid #000; 
  width: 60%;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}
.input-form textarea{
  border: none;
  border-radius: none;
  font-size: 20px;
  outline: none;
  width: 60%;
  border-bottom: 2px solid #000;
  font-family: 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  margin-top: 10px;
}

.direccion-maps{
  grid-area: b;
}

.form-sent{
  padding-top: 20px;
}
.form-sent button{
  width: 300px;
  height: 50px;
  background-color: #a44c84;
  color: white;
  font-size: 20px;
  border-radius: 15px;
  border: none;
}
.form-sent button:hover{
  cursor: pointer;
}
.direccion-maps{
  width: 90%;
  margin: 0 auto;
  text-align: center;
}
.info-direccion-empresa{
 width: 80%; 
}
.info-direccion-empresa p{
  text-align: justify;
  font-size: 20px;
}
.mapa-google{
  width: 80%;
  height: 400px;
  overflow: hidden;
}
.mapa-google iframe{
  width: 600px;
  height: 350px;
}

/* body secccion 2 */
.body-section2{
  background-image: url("../imgcomponents/fondo-hojas.jpg");
  background-size: cover; 
  position: relative;
}
.title-productos{
  font-size: 70px;
  color: white;
  text-align: center;
  font-weight: bold;
  font-family: "Bodoni MT", serif;
}

.cartas-product{
  margin: 0 auto;
  width: 80%;
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  grid-template-areas:
      "a b c d"
      "e f g h"
      "i j k l";
  background-repeat: no-repeat;
  background-size: cover;
  grid-gap: 20px;
}
@media only screen and (max-width: 900px) {
  .cartas-product{
     grid-template-areas:
        "a a b b"
        "c c d d"
        "e e f f"
        "g g h h"
        "i i j j"
        "k k l l";
   }
}
.produc1, .produc2, .produc3, .produc4, .produc5, .produc6, .produc7, .produc8{
  height: 400px;
}
.produc1:hover, .produc2:hover, .produc3:hover, .produc4:hover, .produc5:hover, .produc6:hover, .produc7:hover, .produc8:hover{
  cursor: pointer;
  transform: scale(1.05);
}

.produc1{
  grid-area: a;
  position: relative;
}
.produc1 img, .produc2 img, .produc3 img, .produc4 img, .produc5 img, .produc6 img, .produc7 img, .produc8 img{
  width: 100%;
  height: 100%;
  border-radius: 20px;
}
.produc2{
  grid-area: b; 
  position: relative;
}
.produc3{
  grid-area: c;
  position: relative;
}
.produc4{
  grid-area: d;
  position: relative;
}
.produc5{
  grid-area: e;
  position: relative;
}
.produc6{
  grid-area: f;
  position: relative;
}
.produc7{
  grid-area: g;
  position: relative;
}
.produc8{
  grid-area: h;
  position: relative;
}
.p-prod{
  position: absolute;
  z-index: 10;
  font-size: 30px;
  font-weight: bold;
  color: white;
  text-align: center;
  bottom: 0;
  left: 10%;
}
.prod-capa{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(35, 15, 49);
  background: linear-gradient(0deg, rgb(65, 31, 88) 0%, rgba(237, 76, 178, 0.151) 43%);
  border-radius: 20px;
}
.container-productos{
  width: 100%;
  margin: 0 auto;
}
@media only screen and (max-width: 480px) {
  .body-section2{
    width: 100%;
    position: relative;
    overflow: hidden;
  }
  .title-productos{
    font-size: 50px;
  }
  .cartas-product{
    width: 90%;
    grid-template-areas:
        "a a b b"
        "c c d d"
        "e e f f"
        "g g h h"
        "i i j j"
        "k k l l";
    grid-gap: 10px;
    margin: 0 auto;
    grid-template-columns: 23% 23% 23% 23%;
  }
}
