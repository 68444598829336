.menu-items .menu-flotante{
    display: none;
}
.menu-items .menu-fijo{
    display: flex;
}

@media only screen and (max-width: 480px) {
    .menu-items .menu-flotante{
        display: flex;
    }
    .menu-items .menu-fijo{
        display: none;
    }
}
.menu-fijo2{
    height: 100vh;
    position: fixed;
    background-color: white;
    z-index: 100;
    overflow: hidden;
}
.menu-movil{
    width: 100%;
    position: absolute;
    z-index: 100;
    background-color: white;
    margin-top: 0;
}
.menu-movil li{
    list-style: none;
}
.menu-movil li a{
    text-decoration: none;
}
.menu-movil li{
    text-align: center;
    font-size: 20px;
    height: 40px;
}